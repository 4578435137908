import styled from '@emotion/styled'
import { Button, FormControl, TextField, Paper } from '@mui/material'
import { colors } from '@styles/design-tokens'

export const FormControlWrapper = styled.div`
  padding: 40px 0;
`

export const FormControlStyled = styled(FormControl)`
  width: 100%;
  background-color: ${colors.white};
`

export const TextFieldStyled = styled(TextField)`
`

export const LoginPageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
`

export const LoginPageFormWrapper = styled(Paper)`
    background-color: ${colors.gallery};
    padding: 40px;
    width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
`

export const LoginButtonStyled = styled(Button)`
  margin: 0 auto;
  display: block;
  background-color: ${colors.waterLeaf};
  color: ${colors.blueSapphire};
  font-weight: 700;
  margin-bottom: 0;

  &:hover {
    color: ${colors.waterLeaf};
    background-color: ${colors.blueSapphire};
  }
`

export const LoginFormStyled = styled.form``

export const AlertContainerStyled = styled.div`
  margin: 20px 0 0;
`
