import { AlertMessage } from '@components/AlertMessage'
import { Loader } from '@components/Loader'
import { Logo } from '@components/Logo'
import { LoginApi } from '@helpers/index'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'
import {
  setPassword,
  setUserName,
  setIdToken,
  setUserDetails,
} from '@redux/actions/login'
import { clientId } from '@redux/constants'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  AlertContainerStyled,
  FormControlStyled,
  FormControlWrapper,
  LoginButtonStyled,
  LoginFormStyled,
  LoginPageFormWrapper,
  LoginPageWrapper,
  TextFieldStyled,
} from './styles'

const LoginComponent = ({
  username,
  password,
  setIdToken,
  setUserDetails,
  setPassword,
  setUserName,
}) => {
  const router = useRouter()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showGeneralMessage, setShowGeneralMessage] = useState(false)
  const [alertType, setAlertType] = useState('success')
  const [alertMessage, setAlertMessage] = useState('Success!')

  const loggedOutTimeout = () => {
    setTimeout(() => {
      setShowGeneralMessage(false)
    }, 4000)
  }

  const handleEmail = (e) => {
    const value = e.target.value
    setUserName(value)
  }

  const handlePassword = (e) => {
    const value = e.target.value
    setPassword(value)
  }

  const handleForm = (e) => {
    e.preventDefault()

    setLoading(true)

    let formData = {
      operation: 'login',
      clientId: clientId,
      username: username.trim(),
      password: password.trim(),
      admin: false,
    }

    LoginApi('authenticate', formData).then((response) => {
      if (response.status === '504') {
        setLoading(false)
        setShowGeneralMessage(true)
        setAlertType('error')
        setAlertMessage('We ran into an issue. Our gerbils have been notified.')

        loggedOutTimeout()
        clearTimeout(loggedOutTimeout)
        return false
      }

      if (response.message) {
        setLoading(false)
        setShowGeneralMessage(true)
        setAlertType('error')
        setAlertMessage('We ran into an issue. Our gerbils have been notified.')

        loggedOutTimeout()
        clearTimeout(loggedOutTimeout)
        return false
      }

      if (response.error) {
        setLoading(false)
        setShowGeneralMessage(true)
        setAlertType('error')
        setAlertMessage(
          response.error.desc ||
            'We ran into an issue. Our gerbils have been notified.',
        )

        loggedOutTimeout()
        clearTimeout(loggedOutTimeout)
        return false
      }

      setIdToken(response.data.idToken)
      setUserDetails(response.data.userData)

      router.push('/dashboard')
    })
  }

  const handleMouseDownPassword = (e) => {}

  const handleClickShowPassword = (e) => {
    setShowPassword(!showPassword)
  }

  return (
    <LoginPageWrapper>
      <LoginPageFormWrapper variant='outlined'>
        <Logo />
        {loading && <Loader></Loader>}

        {showGeneralMessage === true && (
          <AlertContainerStyled>
            <AlertMessage severity={alertType} message={alertMessage} />
          </AlertContainerStyled>
        )}

        {/* Login Form */}
        {!loading && (
          <LoginFormStyled onSubmit={handleForm}>
            {/* Username/E-mail */}

            <FormControlWrapper>
              <FormControlStyled>
                <TextFieldStyled
                  required
                  error={!username === '' ? true : false}
                  label='E-mail'
                  value={username}
                  onChange={handleEmail}
                />
              </FormControlStyled>
            </FormControlWrapper>

            {/* Password */}
            <FormControlWrapper>
              <FormControlStyled>
                <InputLabel
                  error={!password === '' ? true : false}
                  htmlFor='password'
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  required
                  id='password'
                  error={!password === '' ? true : false}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePassword}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
              </FormControlStyled>
            </FormControlWrapper>

            <LoginButtonStyled size='large' variant='contained' type='submit'>
              Login
            </LoginButtonStyled>
          </LoginFormStyled>
        )}
      </LoginPageFormWrapper>
    </LoginPageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    username: state.loginReducer.username,
    password: state.loginReducer.password,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setPassword: bindActionCreators(setPassword, dispatch),
    setIdToken: bindActionCreators(setIdToken, dispatch),
    setUserDetails: bindActionCreators(setUserDetails, dispatch),
  }
}

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent)
