import {
  SET_PASSWORD,
  SET_USERNAME,
  SET_ID_TOKEN,
  SET_USERNAME_ERROR,
  SET_PASSWORD_ERROR,
  SET_USER_DETAILS,
} from '@redux/constants'

export const setUserName = (username) => ({
  type: SET_USERNAME,
  payload: username,
})

export const setPassword = (password) => ({
  type: SET_PASSWORD,
  payload: password,
})

export const setIdToken = (token) => ({
  type: SET_ID_TOKEN,
  payload: token,
})

export const setUserNameError = (boolean) => ({
  type: SET_USERNAME_ERROR,
  payload: boolean,
})

export const setPasswordError = (token) => ({
  type: SET_PASSWORD_ERROR,
  payload: token,
})

export const setUserDetails = (details) => ({
  type: SET_USER_DETAILS,
  payload: details,
})
