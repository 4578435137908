import styled from '@emotion/styled'
import { colors } from '@styles/design-tokens'

export const LogoContainer = styled.span`
  display: block;
  background-color: ${colors.middleBlueGreen};
  position: relative;
  z-index: 200;
`

export const ImageStyled = styled.img`
  margin: 0 auto;
  display: block;
  width: 150px;
  border-radius: 40px;
  padding: 10px;

  ${(props) =>
    props.full
      ? `
        border-radius: 0;
        max-width: none;
        width: 100%;
        display: block;
      `
      : ''};
`
export const LogoLink = styled.a`
  cursor: pointer;
  margin: 0 auto;
  display: block;
  width: 150px;
`
