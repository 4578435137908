import Link from 'next/link'
import React from 'react'

import { ImageStyled, LogoContainer, LogoLink } from './styles'

export const Logo = ({ full = false } = props) => {
  return (
    <LogoContainer>
      <Link href='/' passHref>
        <LogoLink title='Talkuccino Home Page'>
          <ImageStyled src={'/images/logo.png'} alt='Talkuccino Logo' />
        </LogoLink>
      </Link>
    </LogoContainer>
  )
}
